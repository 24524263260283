// docs for router https://github.com/thepassle/app-tools/blob/master/router/README.md

import { html } from 'lit';

if (!(globalThis as any).URLPattern) {
  await import("urlpattern-polyfill");
}

import { Router } from '@thepassle/app-tools/router.js';
import { lazy } from '@thepassle/app-tools/router/plugins/lazy.js';

// @ts-ignore
import { title } from '@thepassle/app-tools/router/plugins/title.js';

import './pages/app-home.js';
import './pages/app-login/app-login';
import './pages/app-register/app-register';
//import './pages/app-graph/app-graph';
import './pages/my-comp1/my-comp1';

const baseURL: string = (import.meta as any).env.BASE_URL;

export const router = new Router({
    routes: [
      {
        path: resolveRouterPath(),
        title: 'Home',
        render: () => html`<app-home></app-home>`
      },
      {
        path: resolveRouterPath('login'),
        title: 'Login',
        render: () => html`<app-login></app-login>`
      },
      {
        path: resolveRouterPath('register'),
        title: 'Register',
        render: () => html`<app-register></app-register>`
      },
      {
        path: resolveRouterPath('graph'),
        title: 'graph',
        plugins: [
          lazy(() => import('./pages/app-graph/app-graph')),
        ],
        render: () => html`<app-graph></app-graph>`
      },      {
        path: resolveRouterPath('my-comp1'),
        title: 'my-comp1',
        plugins: [
          lazy(() => import('./pages/my-comp1/my-comp1.js')),
        ],
        render: () => html`<my-comp1></my-comp1>`
      },

      {
        path: resolveRouterPath('about'),
        title: 'About',
        plugins: [
          lazy(() => import('./pages/app-about/app-about.js')),
        ],
        render: () => html`<app-about></app-about>`
      }
    ]
  });

  // This function will resolve a path with whatever Base URL was passed to the vite build process.
  // Use of this function throughout the starter is not required, but highly recommended, especially if you plan to use GitHub Pages to deploy.
  // If no arg is passed to this function, it will return the base URL.

  export function resolveRouterPath(unresolvedPath?: string) {
    var resolvedPath = baseURL;
    if(unresolvedPath) {
      resolvedPath = resolvedPath + unresolvedPath;
    }

    return resolvedPath;
  }
