import { LitElement, html, css } from 'lit';
import { customElement } from 'lit/decorators.js';

@customElement('my-comp1')
export class AppSettings extends LitElement {
    static styles = [
        css`
          <CSS for your page goes here>
        `
    ];

    // firstUpdated() {
    //     const drawer = this.shadowRoot.querySelector('.drawer-placement-start');
    //     const openButton = drawer.nextElementSibling;
    //     const closeButton = drawer.querySelector('sl-button[variant="primary"]');

    //     openButton.addEventListener('click', () => drawer.show());
    //     closeButton.addEventListener('click', () => drawer.hide());
    //   }

    render() {
        return html`
        <app-header></app-header>

        <left-menu></left-menu>

        19.11.2023

        `;
    }
}

// noe tekst her kansje
// <br><br><br><br><br><br>
// <sl-drawer label="Drawer" placement="start" class="drawer-placement-start">

// <sl-dropdown>
// <sl-button slot="trigger" caret>Edit</sl-button>
// <sl-menu>
//   <sl-menu-item value="cut">Cut</sl-menu-item>
//   <sl-menu-item value="copy">Copy</sl-menu-item>
//   <sl-menu-item value="paste">Paste</sl-menu-item>
// </sl-menu>
// </sl-dropdown>

// <br><br><br><br><br><br>

// <sl-menu style="max-width: 300px;">
// <sl-menu-item>
//     <sl-icon slot="prefix" name="house"></sl-icon>
//     Home
// </sl-menu-item>

// <sl-menu-item>
//     <sl-icon slot="prefix" name="envelope"></sl-icon>
//     Messages
//     <sl-badge slot="suffix" variant="primary" pill>12</sl-badge>
// </sl-menu-item>

// <sl-divider></sl-divider>

// <sl-menu-item>
//     <sl-icon slot="prefix" name="gear"></sl-icon>
//     Settings
// </sl-menu-item>
// </sl-menu>




//     <sl-button slot="footer" variant="primary">Close</sl-button>
// </sl-drawer>

// <sl-button>Open Drawer</sl-button>
