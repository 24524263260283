import { LitElement, css, html } from 'lit';
import { property, customElement } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/button/button.js';
import '@shoelace-style/shoelace/dist/components/progress-bar/progress-bar';
import '@shoelace-style/shoelace/dist/components/avatar/avatar';
import '@shoelace-style/shoelace/dist/components/alert/alert';
import '@shoelace-style/shoelace/dist/components/switch/switch';
import '@shoelace-style/shoelace/dist/components/icon/icon.js';
// import '@shoelace-style/shoelace/dist/components/drawer/drawer';

@customElement('app-header')
export class AppHeader extends LitElement {
  @property({ type: String }) title = 'Weight Tracker';

  @property({ type: Boolean}) enableBack: boolean = false;

  static styles = css`
    header {
      background-color: green;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: fixed;
      top: 0;
      z-index: 1000;
      height: 3em;
    }

    header h1 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20px;
      font-weight: bold;
      margin-left: 60px;
    }

    nav a {
      margin-left: 10px;
    }

    #back-button-block {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 12em;
    }

    @media(prefers-color-scheme: light) {
      header {
        color: black;
      }

      nav a {
        color: initial;
      }
    }
  `;

  render() {
    return html`
      <header>
        <h1>${this.title}</h1>
      </header>
    `;
  }
}
