import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';

import '@shoelace-style/shoelace/dist/components/card/card.js';

import { styles } from '../styles/shared-styles';

@customElement('app-home')
export class AppHome extends LitElement {
  @property() message = 'Welcome to Weight Tracker!';

  static styles = [
    styles,
    css`
    #welcomeBar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    #welcomeCard,
    #infoCard {
      padding: 18px;
      padding-top: 0px;
    }

    sl-card::part(footer) {
      display: flex;
      justify-content: flex-end;
    }

    @media(min-width: 750px) {
      sl-card {
        width: 70vw;
      }
    }


    @media (horizontal-viewport-segments: 2) {
      #welcomeBar {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
      }

      #welcomeCard {
        margin-right: 64px;
      }
    }
  `];

  async firstUpdated() {
    console.log('This is your home page');
  }

  share() {
    if ((navigator as any).share) {
      (navigator as any).share({
        title: 'Weight Tracker',
        text: 'Del weight tracker app!',
        url: 'https://lit.meldmegpaa.no',
      });
    }
  }

  render() {
    return html`
      <app-header></app-header>
      <left-menu></left-menu>

      <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <div slot="header">
              <h2>${this.message}</h2>
            </div>

            <p>
              Her kommer det link til brukermanual for Weight Tracker.
            </p>

            <p id="mainInfo">
              Share your weight data with friends, use the share button below!
            </p>

            ${'share' in navigator
        ? html`<sl-button slot="footer" variant="primary" @click="${this.share}">Share you weight data</sl-button>`
        : null}
          </sl-card>
        </div>
      </main>
    `;
  }



}
