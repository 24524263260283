import {html, css, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';
import { openDB } from 'idb';
import { DB_NAME, DB_VERSION } from '../context/context';

@customElement('login-form')
export class SimpleGreeting extends LitElement {
    static styles = css`
    :host {
      display: block;
      max-width: 400px;
      margin: 0 auto;
      padding: 16px;
      box-sizing: border-box;
    }

    form {
      display: flex;
      flex-direction: column;
    }

    input {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 5px;
      border: 1px solid #ccc;
      font-size: 16px;
      text-align: center;
    }

    button {
      padding: 15px;
      background-color: blue;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    button:hover {
      background-color: darkblue;
    }

    .button2 {
        background-color: gray;
        border: none;
        padding: 4px;
        text-align: center;
        border-radius: 25px;
        color: black;
        width: 100%;
        cursor: pointer;
        font-size: 12px;
        margin-top: 16px;
    }
    .button2:hover {  background-color: #018CBA;  }

  `;

  @property({ type: String })  phoneNumber = '';
  @property({ type: String })  code = '';
  db: any;


  constructor() {
    console.log('component Login - constructor');
    super();
    this.initDB();
  }

  async initDB() {
    this.db = await openDB(DB_NAME, DB_VERSION, {
      upgrade(db) {
        db.createObjectStore('menuItems');
        db.createObjectStore('userdata');
      },
    });
  }

  async firstUpdated() {
    console.log('component Login - firstUpdated');
    await this.initDB();
    const tx = this.db.transaction('userdata', 'readonly');
    const store = tx.objectStore('userdata');
    this.phoneNumber = await store.get('phoneNumber');
    if (this.phoneNumber == undefined) {
        this.phoneNumber = '';
    }
    this.code = await store.get('code');
    if (this.code == undefined) {
        this.code = '';
    }
  }

  onPhoneNumberChange(e: any) {
    this.phoneNumber = e.target.value;
    console.log('onPhoneNumberChange', this.phoneNumber.length);
    if (/^\d+$/.test(this.phoneNumber) && this.phoneNumber.length == 8) {
        e.target.setCustomValidity('');
    } else {
        e.target.setCustomValidity('Not valid. PhoneNumber: 8 numbers');
    }
    e.target.reportValidity();
  }

  onCodeChange(e: any) {
    this.code = e.target.value;
  }

  async handleSubmit(e: any) {
    e.preventDefault();
    console.log(`Phone Number: ${this.phoneNumber}, Code: ${this.code}`);
    try {
        const tx = this.db.transaction('userdata', 'readwrite');
        const store = tx.objectStore('userdata');
        await store.put(this.phoneNumber, 'phoneNumber');
        await store.put(this.code, 'code');
        // Optionally, handle transaction completion
        await tx.done;
    } catch (error) {
        console.error('Database transaction failed', error);
    }
    try {
       const data = await loginAPI(this.phoneNumber, this.code);
       console.log(data);
       //const toast = document.createElement('toast-element');
       const toast = document.createElement('toast-element') as any;
       toast.message = data.status + ' ' + data.message;
       toast.visible = true;
       if (data.status == 'Login success') {
            toast.backgroundColor = 'green';
            // update databse with token login ok
            const tx = this.db.transaction('userdata', 'readwrite');
            const store = tx.objectStore('userdata');
            await store.put('OK', 'loginStatus');
         } else {
            toast.backgroundColor = 'red';
            // update databse with token login failed
            const tx = this.db.transaction('userdata', 'readwrite');
            const store = tx.objectStore('userdata');
            await store.put('FAIL', 'loginStatus');
         }
       document.body.appendChild(toast);
        setTimeout(() => {

        toast.visible = false;
            document.body.removeChild(toast);
        }, 3000);

    } catch (error) {
        console.error('There was an error!', error);
        const toast = document.createElement('toast-element') as any;
        toast.message = 'General error' + error;
        toast.visible = true;
        document.body.appendChild(toast);
         setTimeout(() => {

         toast.visible = false;
             document.body.removeChild(toast);
         }, 3000);
    }
    //loginAPI(this.phoneNumber, this.code);
  }

    async handleNewCodeRequest() {
        console.log('Requesting new code SMS');
        makeNewCode(this.phoneNumber);
        //sendSMS(this.phoneNumber);
        // Implement your logic for sending a new code here
    }

  render() {
    return html`
      <form @submit=${this.handleSubmit}>
        <input
          type="number"
          placeholder="Phone Number"
          .value=${this.phoneNumber}
          @input=${this.onPhoneNumberChange}
          maxlength="8"
          pattern="[0-9]{8}"
        />
        <input
          type="number"
          placeholder="Code (4 digits)"
          .value=${this.code}
          @input=${this.onCodeChange}
          maxlength="4"
          pattern="[0-9]{4}"
        />
        <button type="submit">Login</button>
      </form>

      <button class="button2" @click=${this.handleNewCodeRequest}>Send new code SMS</button>


      <toast-element message="Login"></toast-element>
    `;
  }
}

function makeNewCode(phoneNumber: string) {
    console.log('makeNewCode for:', phoneNumber);
    const data = {
        phoneNumber: phoneNumber,
        senderId: 'WeightApp'
    };

    fetch('https://bcbud.one/api/login/api_makeNewCode.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        console.log('response', response); // Logging the response object
        return response.json(); // Parsing the response body as text
    })
    .then(data => {
        console.log('Success:', data)
        if (data.status == "ok") {
            const newCode = data.code;
            sendSMS(phoneNumber, newCode);
        }
    })
    .catch(error => console.error('Error:', error));
}

function sendSMS(phoneNumber: string, newCode: string) {
    console.log('sendSMS', phoneNumber);
    const messageText = 'Your new code is: ' + newCode;
    const data = {
        phoneNumber: phoneNumber,
        senderId: 'WeightApp',
        messageText: messageText
    };

    fetch('https://bcbud.one/api/msg/api_sendSMS.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    }).then((response) => {
        console.log('response', response); // Logging the response object
    });
}

async function loginAPI(phoneNumber: string, code: string) {
    console.log('loginAPI', phoneNumber, code);
    const data = {
        phoneNumber: phoneNumber,
        code: code
    };

    const response = await fetch('https://bcbud.one/api/login/api_checkLogin.php', {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    } else {
        //console.log('response', response); // Logging the response object
    }
    return await response.json();
}