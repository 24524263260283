import { LitElement, html} from 'lit';
import { customElement } from 'lit/decorators.js';

import { styles } from './login-styles';
import { styles as sharedStyles } from '../../styles/shared-styles'

@customElement('app-login')
export class AppSettings extends LitElement {
    static styles = [
      sharedStyles,
      styles
    ]

    // firstUpdated() {
    // }

    render() {
        return html`
        <app-header></app-header>
        <left-menu></left-menu>
        <toast-element></toast-element>

        <main>
          <login-form></login-form>
        </main>

      `;
    }
}


