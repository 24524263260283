import { html, css, LitElement } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { openDB } from 'idb';
import { DB_NAME, DB_VERSION } from '../context/context';
// import { resolveRouterPath } from '../router';

import '@shoelace-style/shoelace/dist/components/drawer/drawer';
import { SlDrawer } from '@shoelace-style/shoelace';
import '@shoelace-style/shoelace/dist/components/menu/menu';
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item';
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown';
// import '@shoelace-style/shoelace/dist/components/icon/icon';
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button';
//import '@shoelace-style/shoelace/dist/components/tooltip';

@customElement('left-menu')
export class LeftMenu extends LitElement {
    static styles = css`
    p { color: blue }
    .open-drawer-button {
      position: fixed;
      top: 0px;
      left: 0px;
      margin: 4px;
      z-index: 1001; /* make it higher than the header's z-index */
    }
    .hamburgericon {
      margin: 2px;
      font-size: 32px;
      color: black;
      background-color: none;
    }
    .close-drawer-button {
      position: fixed;
      top: 0px;
      left: 0px;
      margin: 4px;
      color: black;
      z-index: 1111; /* make it higher than the header's z-index */
    }
    `;

    @property({ type: Array }) menuItems: any[] = [];
    @state() private drawerOpen = false;
    db: any;

    async connectedCallback() {
        super.connectedCallback();
        //this.initDb
        this.menuItems = await this.fetchMenuItems();
    }

    // async initDb() {
    //   await openDB(DB_NAME, DB_VERSION, {
    //     upgrade: (db) => {
    //       //db.createObjectStore('menuItems');
    //       //db.createObjectStore('userdata');
    //       console.log('1 upgrade databse is needed');
    //       //this.writeMenuItems();
    //     },
    //   });
    // }

    async fetchMenuItems() {
        const db = await openDB(DB_NAME, DB_VERSION, {
          upgrade: (db) => {
            db.createObjectStore('menuItems');
            db.createObjectStore('userdata');
            console.log('2 upgrade databse writeMenuItems');
            this.writeMenuItems();
          },
        });
        return db.getAll('menuItems');
    }

    toggleDrawer(show: boolean) {
      const drawer = this.shadowRoot?.querySelector('.drawer-placement-start');
      if (drawer) {
        show ? (drawer as SlDrawer).show() : (drawer as SlDrawer).hide();
        this.drawerOpen = show; // update the state
      }
    }

    async writeMenuItems() {
      console.log('ASYNC writeMenuItems');
      const menuItems = [
        {
          icon: 'door-open',
          name: 'login',
        },
        {
          icon: 'pencil',
          name: 'register',
        },
        {
          icon: 'graph-down-arrow',
          name: 'Graph',
        },
        {
          icon: 'house',
          name: 'about',
        },
        {
          icon: 'envelope',
          name: 'Messages',
          badge: 11,
        },
        {
          icon: 'gear',
          name: 'Settings',
        },
        {
          icon: 'door-closed',
          name: 'Log out',
        }
      ];

      const db = await openDB(DB_NAME, DB_VERSION);
      const tx = db.transaction('menuItems', 'readwrite');
      menuItems.forEach((item, index) => {
        tx.store.put(item, index);
      });
      tx.done;
    }


    render() {
      return html`
        <sl-drawer label="Drawer" placement="start" class="drawer-placement-start">
          <sl-menu style="max-width: 300px;">
            ${this.menuItems.map(item => html`
            <a href="/${item.name.toLowerCase()}">
            <sl-menu-item>
            <sl-icon slot="prefix" name="${item.icon}"></sl-icon>
            ${item.name}
            ${item.badge ? html`<sl-badge slot="suffix" variant="primary" pill>${item.badge}</sl-badge>` : ''}
            </sl-menu-item>
            </a>
            `)}
          </sl-menu>
          <sl-button slot="footer" variant="primary" @click="${() => this.toggleDrawer(false)}">Close</sl-button>
        </sl-drawer>

        ${!this.drawerOpen ? html`
        <sl-button variant="text" class="open-drawer-button" @click="${() => this.toggleDrawer(true)}">
            <sl-icon class="hamburgericon" name="list"></sl-icon>
        </sl-button>
        ` : null}
        ${this.drawerOpen ? html`
            <sl-button class="close-drawer-button" variant="text" @click="${() => this.toggleDrawer(false)}">
                <sl-icon class="hamburgericon" name="x-lg"></sl-icon>
            </sl-button>
        ` : null}
      `;
    }
}

// <sl-button variant="text" class="open-drawer-button" @click="${() => this.toggleDrawer(true)}">
// <sl-icon class="hamburgericon" name="list"></sl-icon>
// </sl-button>