import {html, css, LitElement} from 'lit';
import {customElement, property} from 'lit/decorators.js';

@customElement('toast-element')
export class toastComponent extends LitElement {
    static styles = css`
    :host {
        display: block;
        position: fixed;
        top: 50px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        padding: 10px 10px;
        border-radius: 15px;
        opacity: 0;
        transition: opacity 0.5s ease;
        background-color: var(--toast-background-color, green); /* Default background color */
      }

      :host([visible]) {
        opacity: 1;
      }

      p {
        margin: 0;
        color: white;
      }
    `;

  @property()
  message = 'the toast message';

  @property({type: Boolean, reflect: true})
  visible = false;

  @property({ reflect: true })
  backgroundColor = 'green';

  updated(changedProperties: Map<string | number | symbol, unknown>) {
    super.updated(changedProperties);
    if (changedProperties.has('backgroundColor')) {
      this.style.setProperty('--toast-background-color', this.backgroundColor);
    }
  }

  render() {
    return html`
      <p>${this.message}</p>
    `;
  }
}
