import { LitElement, html} from 'lit';
import { customElement } from 'lit/decorators.js';
import '@shoelace-style/shoelace/dist/components/input/input';
import '@shoelace-style/shoelace/dist/components/alert/alert';
import '@shoelace-style/shoelace/dist/components/icon/icon';
import {checkLoginOK} from '../securitycheck.js';

import { styles } from './register-styles';
import { styles as sharedStyles } from '../../styles/shared-styles'

@customElement('app-register')
export class AppSettings extends LitElement {
    static styles = [
      sharedStyles,
      styles
    ]

    constructor() {
      super();
      this.handleSubmit = this.handleSubmit.bind(this); // Bind here in constructor
    }

    async firstUpdated() {
      const loginOK = await checkLoginOK();
      if (!loginOK) {
        window.history.pushState({}, '', '/login');
        window.dispatchEvent(new PopStateEvent('popstate'));
      }
      // Wait for controls to be defined before attaching form listeners
      Promise.all([
        customElements.whenDefined('sl-button'),
        customElements.whenDefined('sl-input')
      ]).then(() => {
        const form = this.shadowRoot?.querySelector('.input-validation-pattern');
        form?.addEventListener('submit', this.handleSubmit);
        // After ensuring the elements are defined, retrieve values from localStorage
        const currentWeight = localStorage.getItem('currentWeight');
        const currentWeightdecimal = localStorage.getItem('currentWeightdecimal');
        const intakeGrams = localStorage.getItem('intakeGrams');

        const currentWeightInput = this.shadowRoot?.querySelector('sl-input[name="currentWeight"]');
        const currentWeightdecimalInput = this.shadowRoot?.querySelector('sl-input[name="currentWeightdecimal"]');
        const intakeGramsInput = this.shadowRoot?.querySelector('sl-input[name="intakeGrams"]');

        if (currentWeightInput && intakeGrams) {
            (currentWeightInput as HTMLInputElement).value = currentWeight || '';
        }
        if (currentWeightdecimalInput && intakeGrams) {
            (currentWeightdecimalInput as HTMLInputElement).value = currentWeightdecimal || '';
        }
        if (intakeGramsInput && intakeGrams) {
            (intakeGramsInput as HTMLInputElement).value = intakeGrams || '';
        }
      });
    }

    handleSubmit(event: Event): void {
      event.preventDefault();
      const form = event.target as HTMLFormElement;
      const formData = new FormData(form);
      const currentWeight = formData.get('currentWeight') as string;
      const currentWeightdecimal = formData.get('currentWeightdecimal') as string;
      const intakeGrams = formData.get('intakeGrams') as string;
      localStorage.setItem('currentWeight', currentWeight);
      localStorage.setItem('currentWeightdecimal', currentWeightdecimal);
      localStorage.setItem('intakeGrams', intakeGrams);
      this.nyRegistrering(currentWeight, currentWeightdecimal, intakeGrams);
    }

    nyRegistrering(currentWeight: string, currentWeightdecimal: string, intakeGrams: string) {
      console.log('nyRegistrering', currentWeight, currentWeightdecimal, intakeGrams);
      const regTime = Date.now() / 1000;
      // convert string to decimal number
      // const vekt = parseFloat(currentWeight.replace(',', '.'));
      // const matinntak = parseFloat(intakeGrams.replace(',', '.'));
      const w1 = currentWeight.replace(',', '.');
      const w2 = currentWeightdecimal.replace(',', '.');
      const vekt = w1 + "." + w2;
      const matinntak = intakeGrams.replace(',', '.');
      const howMutchToEat = "100";

      const data = {
          datotid: regTime,
          vekt: vekt,
          matinntak: matinntak,
          howMutchToEat: howMutchToEat
      };

      console.log('Data send', data);

      fetch('https://bcbud.one/pages/vekt/savedata3.php', {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
              'Content-Type': 'application/json'
          }
      })
      .then(response => {
        console.log('response', response); // Logging the response object
        return response.text(); // Parsing the response body as text
      })
      .then(data => {
        console.log('Success:', data)
        const toast = document.createElement('toast-element') as any;
        toast.message = 'Nye data er lagt til!: ';
        toast.visible = true;
        toast.backgroundColor = 'green';
        document.body.appendChild(toast);
         setTimeout(() => {
         toast.visible = false;
             document.body.removeChild(toast);
         }, 3000);
      })
      .catch(error => console.error('Error:', error));
    }

    render() {
        return html`
        <app-header></app-header>
        <left-menu></left-menu>

        <main>
        <main>
        <div id="welcomeBar">
          <sl-card id="welcomeCard">
            <h2>Register new weight</h2>

            <form class="input-validation-pattern">
            <sl-input
              type="number"
              placeholder="current weight"
              name="currentWeight"
              required
              label="Current Weight (kg)"
              pattern="^[0-9]+([,.][0-9]+)*$">
            </sl-input>
            <sl-input
              type="number"
              placeholder="1/10 kg"
              name="currentWeightdecimal"
              required
              label="Current Weight (1/10 kg)"
              pattern="^[0-9]+([,.][0-9]+)*$">
            </sl-input>
            <sl-input
              type="number"
              placeholder="intake food weight"
              name="intakeGrams"
              required
              label="intake
              Weight (gram)"
              pattern="[0-9]+">
            </sl-input>
            <br />
            <sl-button type="submit" variant="primary">Save</sl-button>
            <sl-button type="reset" variant="default">Reset</sl-button>
          </form>

          </sl-card>
        <div>
      </main>

      <toast-element></toast-element>

      `;
    }
}

