console.log ('securitycheck - check login status');
import { DB_NAME, DB_VERSION } from '../context/context';
import { openDB } from 'idb';
console.log ('DB_NAME: ' + DB_NAME + ' DB_VERSION: ' + DB_VERSION);

// check-login.ts
export async function checkLoginOK() {
    console.log('checkLoginOK runs now');
    const db = await openDB(DB_NAME, DB_VERSION,);
    const tx = db.transaction('userdata', 'readonly');
    const store = tx.objectStore('userdata');
    const status = await store.get('loginStatus');
    if (status !== 'OK') {
        console.log('loginStatus: ' + status + ' redirect to login page');
        return false;
    }
    return true;
}

