import { css } from 'lit';

// these styles can be imported from any component
// for an example of how to use this, check /pages/about-about.ts
export const styles = css`
#welcomeBar {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#welcomeCard,
#infoCard {
  padding: 18px;
  padding-top: 0px;
  max-width: 400px;
}

@media(min-width: 750px) {
  sl-card {
    width: 70vw;
  }
}
`;